import React from "react"
import BackgroundImage from "../components/BackgroundImage"
import styled from "styled-components"

import Layout from "../components/layout"

import SEO from "../components/seo"

const Styled = styled.div`
  .home {
    min-height: 480px;
    max-height: 600px;

    .home-content {
      height: 73rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;

      h1 {
        margin-top: -10%;
      }
    }
  }
`

const IndexPage = () => (
  <Styled>
    <Layout>
      <SEO title="stevecreswick.com" />
      <div className="home">
        <BackgroundImage key={"home"} author={"baby"}>
          <div class="home-content">
            <h1>Steve Creswick</h1>
            <h2>
              Software Engineer. Minimum Viable Person. Humanity's Last Hope.
            </h2>
          </div>
        </BackgroundImage>
      </div>
    </Layout>
  </Styled>
)

export default IndexPage
